import OperationNatureModel from './operation-nature-model'
import ExchangeBankModel from './exchange-bank-model'
import ExchangeBankTypeModel from './exchange-bank-type-model';
import CustomerLimitModel from './customer-limit-model';

export default class CustomerExchangeBankModel {
  constructor(obj) {
    obj = obj || {};

    let operationNatures = obj.operationNatures?.map(o => new OperationNatureModel(o));

    this.id = obj.id;
    this.bank = new ExchangeBankModel(obj.bank);
    this.exchangeType = new ExchangeBankTypeModel(obj.exchangeType);
    this.operationNatures = operationNatures?.length ? operationNatures : [];
    this.limit = new CustomerLimitModel(obj.limit);
    this.createdAt = obj.createdAt;
    this.updatedAt = obj.updatedAt;
  }
}