export default class CustomerPartnerShareholderModel {
  constructor(obj) {
    obj = obj || {};
    this.id = obj.id;
    this.name = obj.name;
    this.cpfCnpj = obj.cpfCnpj;
    this.share = obj.share;
    this.isLegalPerson = !!obj.isLegalPerson;
    this.createdAt = obj.createdAt;
    this.updatedAt = obj.updatedAt;
  }
}