<template>
  <v-row>
    <v-col class="py-0" cols="12" md="2">
      <v-text-field
        outlined
        dense
        label="Naturalidade"
        v-model="customer.birthplace"
        :rules="[validationIsRequiredField]"
      ></v-text-field>
    </v-col>
    <v-col class="py-0" cols="12" md="4">
      <v-text-field
        outlined
        dense
        label="Nome do pai"
        v-model="customer.fatherName"
        :rules="[validationIsRequiredField]"
      ></v-text-field>
    </v-col>
    <v-col class="py-0" cols="12" md="4">
      <v-text-field
        outlined
        dense
        label="Nome da mãe"
        v-model="customer.motherName"
        :rules="[validationIsRequiredField]"
      ></v-text-field>
    </v-col>
  </v-row>
</template>
<script>

// Mixins
import mixinValidationRules from "@/mixin/mixin-validation-rules";

export default {
  name: "PFOnlyCompleteRemittanceFields",
  mixins: [ mixinValidationRules ],
  components: {  },
  data() {
    return {
      required: [(v) => !!v || `Este campo é obrigatório`],
      customer: this.customerProp,
      listOfCountries: undefined,
    };
  },
  mounted() {},
  methods: {},
  model: {
    prop: "customerProp",
    event: "onChange",
  },
  props: {
    customerProp: {
      type: Object,
    },
  },
  watch: {
    customer() {
      this.$emit("onChange", this.customer);
    },
  },
};
</script>


<style>
</style>