'use strict';

import moment from 'moment';

function maskDateIso(date, valueDateEmpty) {
  if (date) {
    return moment(date).format('DD/MM/YYYY');
  }

  return (valueDateEmpty) ? valueDateEmpty : null;
}

function maskDateAndHourIso(date, valueDateEmpty) {
  if (date) {
    return moment(date).format('DD/MM/YYYY HH:mm:ss');
  }

  return (valueDateEmpty) ? valueDateEmpty : null;
}

function maskDateAndHourWithoutSeconds(date, valueDateEmpty) {
  if (date) {
    return moment(date).format('DD/MM/YYYY HH:mm');
  }

  return (valueDateEmpty) ? valueDateEmpty : null;
}

function formatDateAndHourIso(date, hour) {
  return `${moment(date).format('YYYY-MM-DD')}T${hour || '00:00:00'}`;
}

function endDateBiggerOrEqualStartDate(startDate, endDate) {
  return !startDate || (startDate == '') ||
    !endDate || (endDate == '') ||
    (new Date(startDate) <= new Date(endDate));
}

function maskHourWithoutSeconds(data, valueDateEmpty) {
  if (data) {
    return moment(data).locale('pt-br').format('HH:mm');
  }

  return (valueDateEmpty) ? valueDateEmpty : null;
}

function calculateAge(bornDate, referenceDate) {
  referenceDate = referenceDate || new Date();
  var currentYear = referenceDate.getFullYear();
  var yearBornParts = bornDate.split('/');
  var dayBorn = yearBornParts[0];
  var monthBorn = yearBornParts[1];
  var yearBorn = yearBornParts[2];
  var age = currentYear - yearBorn;
  var currentMonth = referenceDate.getMonth() + 1;

  //Se mes atual for menor que o nascimento, nao fez aniversario ainda;  
  if (currentMonth < monthBorn) {
    age--;
  } else {
    //Se estiver no mes do nascimento, verificar o dia
    if (currentMonth == monthBorn) {
      if (new Date().getDate() < dayBorn) {
        //Se a data atual for menor que o dia de nascimento ele ainda nao fez aniversario
        age--;
      }
    }
  }
  return age;
}

export default {
  maskDateIso,
  maskDateAndHourIso,
  maskDateAndHourWithoutSeconds,
  formatDateAndHourIso,
  endDateBiggerOrEqualStartDate,
  maskHourWithoutSeconds,
  calculateAge,
};