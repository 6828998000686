<template>
  <v-row>
    <v-col class="py-0" cols="12" md="2">
      <v-checkbox
        label="Cel. internacional"
        v-model="internationalCellPhone"
        dense
      ></v-checkbox>
    </v-col>
    <v-col class="py-0 pr-0" cols="12" md="1" v-if="internationalCellPhone">
      <v-text-field
        outlined
        dense
        placeholder="+000"
        v-mask="'+###'"
        inputmode="decimal"
        label="DDI"
        :maxlength="3"
        :rules="[validationIsRequiredField]"
      ></v-text-field>
    </v-col>
    <v-col class="py-0" cols="12" :lg="internationalCellPhone ? 3 : 4">
      <PhoneField
        :isInternationalPhone="internationalCellPhone"
        v-model="customerContactData.cellphone"
        label="Celular"
        :rules="[validationIsRequiredField]"
      />
    </v-col>
    <v-col class="py-0" cols="12" md="3">
      <MailField
        label="E-mail"
        v-model="customerContactData.email"
        :rules="[validationIsRequiredField]"
      />
    </v-col>
    <v-col class="py-0" cols="12" md="3">
      <MailField
        label="E-mail secundário"
        v-model="customerContactData.secondEmail"
      />
    </v-col>
  </v-row>
</template>

<script>
// Components
import PhoneField from "@/components/comum/PhoneField.vue";
import MailField from "@/components/comum/MailField.vue";

// Mixins
import mixinValidationRules from "@/mixin/mixin-validation-rules";

export default {
  name: "PFExchangeBasicCompleteRemittanceFields",
  mixins: [mixinValidationRules],
  components: { PhoneField, MailField },
  model: {
    prop: "model",
    event: "onChange",
  },
  props: {
    model: {
      type: Object,
    },
  },
  watch: {
    customerContactData() {
      this.$emit("onChange", this.customerContactData);
    },
  },
  data() {
    return {
      internationalCellPhone: undefined,
      customerContactData: this.model,
    };
  },
};
</script>

<style>
</style>