<template>
  <div>
    <v-row>
      <v-col class="py-0" cols="12" md="2">
        <v-checkbox
          label="Tel. internacional"
          v-model="internationalLandLine"
          dense
        ></v-checkbox>
      </v-col>
      <v-col class="py-0 pr-0" cols="12" md="1" v-if="internationalLandLine">
        <v-text-field
          outlined
          dense
          placeholder="+000"
          v-mask="'+###'"
          inputmode="decimal"
          label="DDI"
          :maxlength="3"
          v-model="customerContactData.ddi"
        ></v-text-field>
      </v-col>
      <v-col class="py-0" cols="12" :lg="internationalLandLine ? 3 : 4">
        <PhoneField
          :isInternationalPhone="internationalLandLine"
          isLandline
          v-model="customerContactData.landline"
          label="Telefone Fixo"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
// Components
import PhoneField from "@/components/comum/PhoneField.vue";

export default {
  name: "PFOnlyCompleteRemittanceFields",
  components: { PhoneField },
  model: {
    prop: "model",
    event: "onChange",
  },
  props: {
    model: {
      type: Object,
    },
  },
  watch: {
    customerContactData() {
      this.$emit("onChange", this.customerContactData);
    },
  },
  data() {
    return {
      internationalLandLine: undefined,
      customerContactData: this.model,
    };
  },
};
</script>

<style>
</style>