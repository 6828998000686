<template>
  <v-row>
    <v-col cols="12" md="12">
      <div class="box">
        <h1>Informações iniciais</h1>
        <v-divider class="py-3"></v-divider>
        <PFCustomerCover
          v-if="isPF"
          :isPF="isPF"
          :isBasicRemitance="isBasicRemitance"
          :isCompleteRemittance="isCompleteRemittance"
          v-model="customer"
        />
        <PJCustomerCover v-else v-model="customer" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
// Components
import PFCustomerCover from "./PF/cover/PFCustomerCover.vue";
import PJCustomerCover from "./PJ/cover/PJCustomerCover.vue";

// Constants
import { REGISTER_TYPE } from "@/constants/general-constants.js";

export default {
  name: "CustomerCover",
  components: {
    PFCustomerCover,
    PJCustomerCover,
  },
  model: {
    prop: "customerProp",
    event: "onChange",
  },
  props: {
    customerProp: {
      type: Object,
    },
    isBasicRemitance: {
      type: Boolean,
    },
    isPF: {
      type: Boolean,
    },
    isCompleteRemittance: {
      type: Boolean,
    },
  },
  watch: {
    customer() {
      this.$emit("onChange", this.customer);
    },
  },
  computed: {},
  data() {
    return {
      customer: this.customerProp,
      loading: false,
      listOfRegisterTypes: Object.values(REGISTER_TYPE),
    };
  },
};
</script>

<style></style>
