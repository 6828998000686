<template>
  <div class="py-2">
    <PFFinancialExchange v-if="isExchange" v-model="customer" />
    <PFFinancialRemittance v-if="isRemitance" v-model="customer" />
    <PFFinancialCompleteRemittance
      v-if="isCompleteRemittance"
      v-model="customer"
    />
  </div>
</template>

<script>
// Components
import PFFinancialCompleteRemittance from "@/components/form-customer/PF/financial/PFFinancialCompleteRemittance.vue";
import PFFinancialRemittance from "@/components/form-customer/PF/financial/PFFinancialRemittance.vue";
import PFFinancialExchange from "@/components/form-customer/PF/financial/PFFinancialExchange.vue";

export default {
  name: "PFCustomerFinancial",
  components: {
    PFFinancialCompleteRemittance,
    PFFinancialRemittance,
    PFFinancialExchange,
  },
  model: {
    prop: "customerProp",
    event: "onChange",
  },
  props: {
    customerProp: {
      type: Object,
    },
    isExchange: {
      type: Boolean
    },
    isRemitance: {
      type: Boolean,
    },
    isCompleteRemittance: {
      type: Boolean,
    },
  },
  data() {
    return {
      customer: this.customerProp,
    };
  },
  watch: {
    customer() {
      this.$emit("onChange", this.customer);
    },
  },
};
</script>

<style>
</style>