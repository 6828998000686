const mixinGeral = {
  data() {
    return {
      // isDark: false
    }
  },
  computed: {
    isDark() {
      return (this.$vuetify.theme.dark)
        ? true
        : false
    }
  },

  methods: {
  }
}

export default mixinGeral