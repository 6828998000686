<template>
  <v-row>
    <v-col cols="12" md="12">
      <div class="box">
        <h1>Compliance</h1>
        <v-divider class="py-3"></v-divider>
        <PJCustomerCompliance v-model="customer" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
// Components
import PJCustomerCompliance from "./PJ/compliance/PJCustomerCompliance.vue";

export default {
  name: "CustomerCompliance",
  components: { PJCustomerCompliance },
  model: {
    prop: "customerProp",
    event: "onChange",
  },
  props: {
    customerProp: {
      type: Object,
    },
  },
  watch: {
    customer() {
      this.$emit("onChange", this.customer);
    },
  },
  computed: {
    
  },
  data() {
    return {
      customer: this.customerProp,
    };
  },
};
</script>

<style>
</style>