import axios from 'axios';
import { URLS } from '@/constants/configurations-constants';

function getBanks() {
  return new Promise((resolve, reject) => {
    axios.get(`${URLS.BRASILAPI}/banks/v1`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}
function getBanksByCode(code) {
  return new Promise((resolve, reject) => {
    axios.get(`${URLS.BRASILAPI}/banks/v1/ ${code}`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}


export default {
  getBanks,
  getBanksByCode
};
