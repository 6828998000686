<template>
  <v-row>
    <v-col cols="12" md="2">
      <MoneyField
        label="Renda média mensal"
        prefix="R$ "
        v-model="customer.additional.averageMonthlyIncome"
        :rules="[validationIsRequiredFieldNumeric]"
      />
    </v-col>
    <v-col cols="12" md="4">
        <v-text-field
          v-model="customer.occupation.profession"
          outlined
          dense
          label="Profissão"
          :rules="[validationIsRequiredField]"
        ></v-text-field>
      </v-col>
  </v-row>
</template>

<script>
import MoneyField from "@/components/comum/MoneyField.vue";

// Mixins
import mixinValidationRules from "@/mixin/mixin-validation-rules";

export default {
  name: "PFFinancialExchange",
  mixins: [ mixinValidationRules ],
  components: {
    MoneyField,
  },
  model: {
    prop: "customerProp",
    event: "onChange",
  },
  props: {
    customerProp: {
      type: Object,
    },
  },
  data() {
    return {
      customer: this.customerProp,
    };
  },
  watch: {
    customer() {
      this.$emit("onChange", this.customer);
    },
  },
};
</script>

<style>
</style>