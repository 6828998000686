<template>
  <!-- eslint-disable-next-line vue/no-mutating-props -->
  <v-dialog v-model="openWindow" persistent max-width="500">
    <v-card>
      <v-card-title v-html="message" class="message"> </v-card-title>
      <v-card-text class="text-center">
        <v-btn text @click="callbackPrimary()" class="btn-primary mr-3">
          {{ textPrimaryButton }}
        </v-btn>
        <v-btn text class="btn-secondary" @click="closeWindow">
          {{ textSecondaryButton }}
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "AlteracaoStatus",
  model: {
    prop: "openWindow",
    event: "onChange",
  },
  data() {
    return {
      janelaAberta: this.openWindow,
    };
  },
  props: {
    message: { type: String, default: "Confirma a alteração do status?" },
    textPrimaryButton: { type: String, default: "Sim" },
    textSecondaryButton: { type: String, default: "Não" },
    openWindow: { type: Boolean, default: false },
    callbackPrimary: { type: Function, default: () => {} },
    // callbackSecondary: { type: Function, default: ()=> {}},
  },

  methods: {
    closeWindow() {
      this.janelaAberta = false;
      this.$emit("onChange", this.janelaAberta);
    },
  },
};
</script>
<style scoped>
.message {
  text-align: center;
  margin: 0 auto;
  display: block;
  padding: 20px;
  word-break: normal;
}

div.v-card__text {
  margin-top: 15px;
  text-align: end;
}
.v-card__title .message {
  font-size: 10px;
  font-weight: 200;
  text-align: center !important;
}
</style>
