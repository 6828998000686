<template>
  <v-row v-if="isRemittance" >
    <v-col cols="12" md="12">
      <div class="box">
        <h1>Conta no Exterior</h1>
        <v-divider class="py-3"></v-divider>
        <PFInternationalBank v-model="customer" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
// Components
import PFInternationalBank from "./PF/internationalAccount/PFInternationalBank.vue";

// Constants
import { REGISTER_TYPE } from "@/constants/general-constants.js";

export default {
  name: "CustomerInternationalAccount",
  components: { PFInternationalBank },
  model: {
    prop: "customerProp",
    event: "onChange",
  },
  props: {
    customerProp: {
      type: Object,
    },
  },
  watch: {
    customer() {
      this.$emit("onChange", this.customer);
    },
  },
  computed: {
    isRemittance() {
      return this.customer &&
        this.customer.registerType.id == REGISTER_TYPE.BASIC_REMITTANCE.id ||
        this.customer.registerType.id == REGISTER_TYPE.COMPLETE_REMITTANCE.id
        ? true
        : false;
    },
  },
  data() {
    return {
      customer: this.customerProp,
      listOfRegisterTypes: Object.values(REGISTER_TYPE),
    };
  },
};
</script>

<style>
</style>