// Tracking
import mixpanel from "mixpanel-browser";


const mixinMessage = {
  data() {
    return {
      message: '',
      snackbar: false,
      timeout: 15000,
      type: '',
    }
  },
  methods: {
    sendMessage(message, type, timeout) {
      this.type = type;
      this.message = message;
      this.snackbar = !this.snackbar;
      this.timeout = timeout || this.timeout;
      mixpanel.track(type, { message: message });
    }
  }
}

export default mixinMessage;