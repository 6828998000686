import OperationalDocumentTypeModel from "./operational-document-type-model";

export default class CustomerOperationalDocumentModel {
  constructor(obj) {
    obj = obj || {};
    this.id = obj.id;
    this.customerId = obj.customerId;
    this.operationalDocumentType = new OperationalDocumentTypeModel(obj.operationalDocumentType);
    this.path = obj.path;
    this.document = obj.document;
    this.size = obj.size;
    this.createdAt = obj.createdAt;
    this.updatedAt = obj.updatedAt;
  }
}