<template>
  <MoneyField
    label="Renda média mensal"
    v-model="customer.additional.averageMonthlyIncome"
    :rules="[validationIsRequiredFieldNumeric]"
  />
</template>

<script>
import MoneyField from "@/components/comum/MoneyField";

// Mixins
import mixinValidationRules from "@/mixin/mixin-validation-rules";

export default {
  name: "PFFinancialRemittance",
  mixins: [ mixinValidationRules ],
  components: { MoneyField },
  model: {
    prop: "customerProp",
    event: "onChange",
  },
  props: {
    customerProp: {
      type: Object,
    },
  },
  data() {
    return {
      customer: this.customerProp,
    };
  },
  watch: {
    customer() {
      this.$emit("onChange", this.customer);
    },
  },
};
</script>

<style></style>
