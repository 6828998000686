<template>
  <v-autocomplete
    :label="label"
    :items="listOfCountries"
    item-text="nome.abreviado"
    v-model="countryData"
    clearable
    dense
    outlined
    v-disabled-icon-focus
    :rules="rules"
  >
  </v-autocomplete>
</template>

<script>
// API
import apiIBGE from "@/api/general/ibge-api";

export default {
  name: "CountryField",
  model: {
    prop: "model",
    event: "onChange",
  },
  watch: {
    countryData() {
      this.$emit("onChange", this.countryData);
    },
  },
  props: {
    model: {
      type: String,
    },
    label: {
      type: String,
      required: true,
    },
    rules: { type: Array, required: false },
  },
  data() {
    return {
      listOfCountries: undefined,
      countryData: this.model,
    };
  },
  mounted() {
    this.getCountry();
  },
  methods: {
    getCountry() {
      apiIBGE.getCountry().then((resposta) => {
        this.listOfCountries = resposta.data.map((nome) => nome);
      });
    },
  },
};
</script>
