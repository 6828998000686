import utilsStorage from '@/utils/storage';

import UserModel from '@/model/user-model';

import { ACCESS_ROUTES } from '../constants/general-constants';


const mixinAuthorization = {
  data() {
    return {
      user: new UserModel()
    }
  },
  mounted() {
    this.getUser();
  },

  methods: {
    getUser() {
      this.user = new UserModel(utilsStorage.getUserOnStorage());
      return this.user;
    },

    /**
     * This method return a boolean if this user can or can't access this route
     * @param {Route} routeName Name of rote to verify.
     * @returns return True ou False
     */
    permissionToAccessMenuByRoute(routeName) {
      switch (routeName) {
        case ACCESS_ROUTES.DASHBOARD.description:
          return !!ACCESS_ROUTES.DASHBOARD.profiles.some(id => id == this.user.profileId);

        case ACCESS_ROUTES.USERS.description:
          return !!ACCESS_ROUTES.USERS.profiles.some(id => id == this.user.profileId);
      }
    }
  },

  computed: {
    isAdministrativeAccess() {
      return this.user.profileId == 1;
    }
  }
}

export default mixinAuthorization;