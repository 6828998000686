<template>
  <v-row class="py-2">
    <v-col class="py-0" cols="12" md="3">
      <v-text-field
        v-model="customer.additional.nameRegistrationResponsible"
        outlined
        dense
        label="Nome"
        :rules="[validationIsRequiredField]"
      ></v-text-field>
    </v-col>
    <v-col class="py-0" cols="12" md="3">
      <v-text-field
        v-model="customer.additional.positonRegistrationResponsible"
        outlined
        dense
        label="Cargo"
      ></v-text-field>
    </v-col>
    <v-col class="py-0" cols="12" md="3">
      <v-text-field
        v-model="customer.additional.emailRegistrationResponsible"
        outlined
        dense
        label="Email"
      ></v-text-field>
    </v-col>
    <v-col class="py-0" cols="12" md="3">
      <v-text-field
        v-model="customer.additional.cellphoneRegistrationResponsible"
        outlined
        dense
        label="Telefone"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>

// Mixins
import mixinValidationRules from "@/mixin/mixin-validation-rules";

export default {
  name: "PJCustomerResponsibleRegistration",
  mixins: [ mixinValidationRules ],
  model: {
    prop: "customerProp",
    event: "onChange",
  },
  props: {
    customerProp: {
      type: Object,
    },
  },
  data() {
    return {
      customer: this.customerProp,
    };
  },
  watch: {
    customer() {
      this.$emit("onChange", this.customer);
    },
  },
};
</script>

<style>
</style>