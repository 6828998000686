<template>
  <div class="py-2">
    <PFExchangeBasicCompleteRemittanceFields
      v-if="isPF"
      :isBasicRemittance="isBasicRemitance"
      :isCompleteRemittance="isCompleteRemittance"
      v-model="customer"
    />
    <PFOnlyCompleteRemittanceFields
      v-if="isCompleteRemittance"
      v-model="customer"
    ></PFOnlyCompleteRemittanceFields>
  </div>
</template>

<script>
// Components
import PFOnlyCompleteRemittanceFields from "./PFOnlyCompleteRemittanceFields.vue";
import PFExchangeBasicCompleteRemittanceFields from "./PFExchangeBasicCompleteRemittanceFields.vue";

// Constants
import { REGISTER_TYPE } from "@/constants/general-constants";

export default {
  name: "PFCustomerCover",
  model: {
    prop: "customerProp",
    event: "onChange",
  },
  components: {
    PFOnlyCompleteRemittanceFields,
    PFExchangeBasicCompleteRemittanceFields,
  },
  props: {
    customerProp: {
      type: Object,
    },
    isPF: {
      type: Boolean,
    },
    isBasicRemitance: {
      type: Boolean,
    },
    isCompleteRemittance: {
      type: Boolean,
    },
  },
  watch: {
    customer() {
      this.$emit("onChange", this.customer);
    },
  },
  computed: { },
  data() {
    return {
      customer: this.customerProp,
      required: [(v) => !!v || `Este campo é obrigatório`],
      loading: false,
      listOfRegisterTypes: Object.values(REGISTER_TYPE),
    };
  },
};
</script>

<style>
</style>
