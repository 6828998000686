<template>
  <v-row>
    <v-col class="py-0" cols="12" md="3">
      <MoneyField
        label="Faturamento bruto anual"
        v-model="customer.additional.annualGrossRevenue"
        :rules="[validationIsRequiredFieldNumeric]"
      />
    </v-col>
    <v-col class="py-0" cols="12" md="3">
      <MoneyField
        label="Capital social integralizado"
        v-model="customer.additional.integratedSocialCapital"
        :rules="[validationIsRequiredFieldNumeric]"
      />
    </v-col>
    <v-col class="py-0" cols="12" md="3">
      <v-autocomplete
        label="Patrimônio"
        :items="listOfAssets"
        hide-selected
        v-model="customer.additional.assets"
        clearable
        dense
        outlined
        item-text="description"
        item-value="id"
        v-disabled-icon-focus
        :rules="[validationIsRequiredAutocompleteText]"
      >
      </v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import MoneyField from "@/components/comum/MoneyField";
import { ASSETS } from "@/constants/general-constants";

// Mixins
import mixinValidationRules from "@/mixin/mixin-validation-rules";

export default {
  name: "PJCustomerFinancial",
  mixins: [ mixinValidationRules ],
  components: { MoneyField },
  model: {
    prop: "customerProp",
    event: "onChange",
  },
  props: {
    customerProp: {
      type: Object,
    },
  },
  data() {
    return {
      customer: this.customerProp,
      listOfAssets: ASSETS,
    };
  },
  watch: {
    customer() {
      this.$emit("onChange", this.customer);
    },
  },
};
</script>

<style></style>
