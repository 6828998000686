export default class CurrencyModel {
  constructor(obj) {
    obj = obj || {};
    this.id = obj.id;
    this.name = obj.name;
    this.code = obj.code;
    this.minimumSpreadSell = (obj.minimumSpreadSell && parseFloat(obj.minimumSpreadSell)) || 0;
    this.maximumSpreadSell = (obj.maximumSpreadSell && parseFloat(obj.maximumSpreadSell)) || 0;
    this.recommendedSpreadSell = (obj.recommendedSpreadSell && parseFloat(obj.recommendedSpreadSell)) || 0;
    this.minimumSpreadBuy = (obj.minimumSpreadBuy && parseFloat(obj.minimumSpreadBuy)) || 0;
    this.maximumSpreadBuy = (obj.maximumSpreadBuy && parseFloat(obj.maximumSpreadBuy)) || 0;
    this.recommendedSpreadBuy = (obj.recommendedSpreadBuy && parseFloat(obj.recommendedSpreadBuy)) || 0;
    this.createdAt = obj.createdAt;
    this.updatedAt = obj.updatedAt;
  }
}