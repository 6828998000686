export default class CustomerLimitModel {
  constructor(obj) {
    obj = obj || {};

    this.id = obj.id;
    this.value = obj.value;
    this.remaining = obj.remaining;
    this.expireAt = obj.expireAt;
    this.createdAt = obj.createdAt;
    this.updatedAt = obj.updatedAt;
  }
}
