import PaymentModeModel from './payment-mode-model';
import HabilitationModeModel from './habilitation-mode-model';
import OperatingModeModel from './operating-mode-model';
import BusinessPurposeTypeModel from './business-purpose-model';

export default class CustomerComplianceDTO {
  constructor(obj) {
    obj = obj || {};

    this.id = obj.id;
    this.purposeBusinessRelationship = obj.purposeBusinessRelationship?.map(bt => new BusinessPurposeTypeModel(bt)) || [];
    this.paymentMode = new PaymentModeModel(obj.paymentMode);
    this.habilitationMode = new HabilitationModeModel(obj.habilitationMode);
    this.operatingMode = new OperatingModeModel(obj.operatingMode);
    this.suppliers = obj.suppliers;
    this.products = obj.products;
    this.typeExportedProducts = obj.typeExportedProducts;
    this.estimatedAmount = obj.estimatedAmount;
    this.answerCrimes = obj.answerCrimes;
    this.additionalInformation = obj.additionalInformation;
    this.otherPurposeBusinessRelationship = obj.otherPurposeBusinessRelationship;
    this.registeredDiLast6Months = !!obj.registeredDiLast6Months;
    this.dis = obj.dis;
    this.createdAt = obj.createdAt;
    this.updatedAt = obj.updatedAt;

    this.suppliersProxy = obj.suppliers ? obj.suppliers?.split(';').map(i => ({ name: i })) : [];
    this.productsProxy = obj.products ? obj.products?.split(';').map(i => ({ name: i })) : [];
    this.typeExportedProductsProxy = obj.typeExportedProducts ? obj.typeExportedProducts?.split(';').map(i => ({ name: i })) : [];
    this.disProxy = obj.dis ? obj.dis?.split(';').map(i => ({ name: i })) : [];

  }
}