<template>
  <div>
    <v-snackbar
      v-model="snackbar"
      :vertical="false"
      :timeout="timeout"
      top="top"
      :color="type"
      elevation="0"
      right
    >
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>

    <v-row>
      <v-col class="py-0" cols="12" :md="hasPix ? '3' : '5'">
        <v-autocomplete
          label="Banco"
          :item-text="itemTextBank"
          clearable
          item-value="code"
          dense
          outlined
          v-disabled-icon-focus
          v-model="bank.bank"
          :items="listOfBanks"
          :rules="required ? [validationIsRequiredAutocompleteText] : []"
        >
        </v-autocomplete>
      </v-col>
      <v-col class="py-0" cols="12" md="3">
        <v-text-field
          v-model="bank.agency"
          outlined
          dense
          label="Agência"
          :rules="required ? [validationIsRequiredField] : []"
        ></v-text-field>
      </v-col>
      <v-col class="py-0" cols="12" md="3">
        <v-text-field
          v-model="bank.account"
          outlined
          dense
          label="Conta"
          :rules="required ? [validationIsRequiredField] : []"
        ></v-text-field>
      </v-col>
      <v-col class="py-0" cols="12" md="1">
        <v-text-field
          v-model="bank.digit"
          outlined
          dense
          label="Digito"
          :rules="required ? [validationIsRequiredField] : []"
        ></v-text-field>
      </v-col>
      <v-col v-if="hasPix" class="py-0" cols="12" md="2">
        <v-text-field
          v-model="bank.pix"
          outlined
          dense
          label="Chave Pix"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// Constants

// APIs
import apiBanks from "@/api/general/bank-api";

// Mixins
import mixinMessage from "@/mixin/mixin-message";
import mixinValidationRules from "@/mixin/mixin-validation-rules";

// Components

export default {
  name: "BankComponent",
  mixins: [ mixinMessage, mixinValidationRules ],
  components: {  },
  model: {
    prop: "bankProp",
    event: "onChange",
  },
  props: {
    bankProp: { type: Object, },
    hasPix: { type: Boolean, default: false, },
    required: { type: Boolean, default: false, },
  },
  data() {
    return {
      bank: this.bankProp,
      listOfBanks: [],
    };
  },

  watch: {
    bank: {
      handler() {
        this.$emit("onChange", this.bank);
      },
      deep: true,
    },
  },

  mounted() {
    this.getBankList();
  },

  computed: {
    itemTextBank() {
      return (item) => !item.code ? '000 - ' + item.name.toUpperCase() : item.code + ' - ' + item.name.toUpperCase();
    }
  },

  methods: {
    getBankList() {
      apiBanks
        .getBanks()
        .then((response) => {
          this.listOfBanks = response.data.reduce((accumulator, current) => {
            if (current.name == undefined || !current.code) {
              return accumulator;
            } 
            accumulator.push(current);
            return accumulator;
          }, []);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
